import {
  Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input,
  TemplateRef, ComponentFactoryResolver, ApplicationRef, EmbeddedViewRef, Injector, AfterViewInit
} from '@angular/core';

import * as L from 'leaflet';
import {ILeafletLayer, LeafletLayer} from './leaflet';
import { IEntityLocation } from 'src/app/entities/entity-location';

@Component({
  selector: 'app-leaflet',
  templateUrl: './leaflet.component.html',
  styleUrls: ['./leaflet.component.scss']
})
export class LeafletComponent implements OnInit, AfterViewInit {
  private leafletMap: any;

  @Input() imagePath = '/assets/libs/leaflet/images/';
  @Input() height = '650px';
  @Input() defaultViewPoint = {lat: '10.801940', lng: '106.738450'};
  // @Input() defaultPopupContent = 'Oxanh Farm';
  @Input() popupTemplateRef: TemplateRef<any>;

  @Input() setViewMap: EventEmitter<any> = new EventEmitter();
  @Input() drawCircle: EventEmitter<any> = new EventEmitter();
  // @Input() drawPolygon: EventEmitter<any> = new EventEmitter();
  // @Input() drawGeoJson: EventEmitter<any> = new EventEmitter();

  @Output() load: EventEmitter<any> = new EventEmitter();
  @Output() popupOpen: EventEmitter<any> = new EventEmitter();
  @Output() clickLayer: EventEmitter<ILeafletLayer> = new EventEmitter();

  @ViewChild('map') map: ElementRef;
  // private defaultMarker: any;
  private defaultLatLng: any;
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private applicationRef: ApplicationRef,
  ) {
    L.Icon.Default.imagePath = this.imagePath;
    this.defaultLatLng = L.latLng(this.defaultViewPoint.lat, this.defaultViewPoint.lng);
    // this.defaultMarker = L.marker(this.defaultLatLng, {});
  }

  ngOnInit() {
    this.setViewMap.subscribe((point: IEntityLocation) => this._setViewMap(point));
    this.drawCircle.subscribe((points: IEntityLocation[]) => this._drawCircle(points));
    // this.drawGeoJson.subscribe((points: any[]) => this._drawGeoJson(points));
  }

  ngAfterViewInit() {
    this._drawMap();
  }

  private _setViewMap(point:IEntityLocation) {
    this.defaultLatLng = L.latLng(point.pixelX, point.pixelY);
    // this.leafletMap.setView(target, zoom);
  }

  private _drawMap() {
    // Height has to be set. You can do this in CSS too.
    this.map.nativeElement.style = `height:${this.height};`;
    // Define map
    this.leafletMap = L.map(this.map.nativeElement);
    // Add tiles map
    const tiles = L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    });
    tiles.addTo(this.leafletMap);
    // Set map's center to target with zoom 14.

    // Place a marker on the same location.
    // this.defaultMarker.addTo(this.leafletMap);
    // this.defaultMarker.bindPopup('Oxanh Farm').openPopup();
    this.leafletMap.on('popupopen', (e) => this.popupOpen.emit(e));
    this.leafletMap.on('load', (e) => setTimeout(() => this.load.emit(e), 100));
    this.leafletMap.setView(this.defaultLatLng, 13);
  }

  // private _drawPolyline(points: any[]) {
  //   return L.polyline(points).addTo(this.leafletMap);
  // }
  private addMarkerWithIcon(point: IEntityLocation): void {
    // Define a custom icon
    const customIcon = L.icon({
      iconUrl: 'assets/images/farm.svg', // Path to your custom icon
      iconSize: [38, 38], // Size of the icon
      iconAnchor: [22, 38], // Point of the icon which will correspond to marker's location
      popupAnchor: [-3, -76] // Point from which the popup should open relative to the iconAnchor
    });
    // Create a marker with the custom icon
    const marker = L.marker([point.pixelX, point.pixelY], { icon: customIcon }).addTo(this.leafletMap);
    // Optionally bind a popup to the marker
    marker.bindPopup(point.tbEntityName).openPopup();
  }

  private _drawCircle(points: IEntityLocation[]) {
    for (let index = 0; index < points.length; index++) {
      const element = points[index];
      L.circle([element.pixelX, element.pixelY], {
        color: 'red',
        fillColor: '#f03',
        fillOpacity: 0.5,
        radius: 1000
      }).addTo(this.leafletMap);
      // circle.bindPopup(point.tbEntityName).openPopup();
      this.addMarkerWithIcon(element);
    }
  }

  // private _drawGeoJson(json: any[]) {
  //   const geojsonMarkerOptions = {
  //     radius: 8,
  //     fillColor: '#ff7800',
  //     color: '#000',
  //     weight: 1,
  //     opacity: 1,
  //     fillOpacity: 0.8
  //   };

  //   const geometries = L.geoJSON(json, {
  //     style: function (feature) {
  //       switch (feature.properties.party) {
  //         case 'Republican': return {color: '#ff0000'};
  //         case 'Democrat': return {color: '#0000ff'};
  //       }
  //     },
  //     onEachFeature: (feature, layer) => {
  //       // does this feature have a property named popupContent?
  //       if (feature.properties) {
  //         if (feature.properties.popup) {
  //           const popupProps = feature.properties.popup;
  //           const popup = L.popup();
  //           if (popupProps.content) {
  //             popup.setContent(popupProps.content);
  //           } else if (popupProps.component) {
  //             const component = popupProps.component;
  //             const componentFactory = this.componentFactoryResolver.resolveComponentFactory<any>(component);
  //             const componentRef = componentFactory.create(this.injector);
  //             componentRef.instance.leafletLayer = new LeafletLayer(layer);

  //             this.applicationRef.attachView(componentRef.hostView);

  //             const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
  //               .rootNodes[0] as HTMLElement;
  //             popup.setContent(domElem);

  //             // this.applicationRef.detachView(componentRef.hostView);
  //             // componentRef.destroy();
  //           }

  //           if (this.popupTemplateRef && this.popupTemplateRef instanceof TemplateRef) {
  //           }
  //           layer.bindPopup(popup);
  //         }
  //         layer.on('click', () => this.clickLayer.emit(new LeafletLayer(layer)));
  //       }
  //     },
  //     pointToLayer: function (feature, latlng) {
  //       return L.circleMarker(latlng, geojsonMarkerOptions);
  //     },
  //   }).addTo(this.leafletMap);
  //   return geometries;
  // }
}
