import {Injectable} from '@angular/core';
import {UserService} from './user.service';
import {IPermission, IRule} from './user.model';
import {LocalStorageService} from 'ngx-webstorage';
import {isArray} from "util";


export interface IRuleToPermission {
  per: string;
  rule: IRule;
}

export const ruleToPermission: IRuleToPermission[] = [
  {per: 'can_read_dashboard', rule: {v5: 'getapiusers'}},

  {per: 'can_read_user', rule: {v5: 'getapiusers'}}, // danh sách user
  {per: 'can_create_user', rule: {v5: 'postapiusers'}}, // thêm user
  {per: 'can_update_user', rule: {v5: 'putapiusers'}}, // sửa user
  {per: 'can_delete_user', rule: {v5: 'deleteapiusers'}}, // xóa user
  {per: 'can_change_password_profile', rule: {v5: 'postapiaccountchangepassword'}}, // đổi mk
  {per: 'can_update_my_profile', rule: {v5: 'putapiupdatemyprofile'}}, // cập nhật profile

  {per: 'can_read_role', rule: {v5: 'getapiroles'}}, // get role
  {per: 'can_create_role', rule: {v5: 'postapiroles'}}, // thêm role
  {per: 'can_update_role', rule: {v5: 'putapiroles'}}, // update role
  {per: 'can_delete_role', rule: {v5: 'deleteapiroles'}}, // xoas role
  {per: 'can_read_rule', rule: {v5: 'getapirules'}}, // xem quyen
  {per: 'can_update_rule', rule: {v5: 'putapiupdatelistrules'}}, // update list rule

  {per: 'can_read_crops', rule: {v5: 'getapitbcrops'}}, // module canh tac
  {per: 'can_create_crops', rule: {v5: 'postapitbcrops'}},
  {per: 'can_update_crops', rule: {v5: 'putapitbcrops'}},
  {per: 'can_delete_crops', rule: {v5: 'deleteapitbcrops'}},
  {per: 'can_read_detail_crops', rule: {v5: 'getapitbcrops'}},
  {per: 'can_read_search_crops', rule: {v5: 'searchapitbcrops'}},
  {per: 'can_put_crops_personincharge', rule: {v5: 'putapitbcropsupdatepersonincharge'}},
  {per: 'can_export_nursery', rule: {v5: 'getapireportnurserymonitor'}}, // bao cao vuon uom
  {per: 'can_export_cultivate', rule: {v5: 'getapireportcultivatehistory'}}, // bao cao canh tac

  {per: 'can_read_harvests', rule: {v5: 'can_read_harvests'}}, // module thu hoach
  {per: 'can_detail_harvests', rule: {v5: 'can_detail_harvests'}},

  {per: 'can_report_monthly_activity', rule: {v5: 'getapireportmonthlyactivity'}}, // bao cao theo thang
  {per: 'can_report_garden', rule: {v5: 'getapireportgardensupervision'}}, // giam sat vuon
  {per: 'can_report_seedusage', rule: {v5: 'getapireportseedusage'}}, // giong su dung
  {per: 'can_report_exportwarehouse', rule: {v5: 'getapireportimportexportwarehouse'}}, // nhap xuat kho
  {per: 'can_report_importmaterial', rule: {v5: 'getapireportimportmaterial'}}, // nhap lieu dau vao

  {per: 'can_tbguidelines', rule: {v5: 'getapitbguidelines'}}, // danh sach tieu chuan
  {per: 'can_create_tbguidelines', rule: {v5: 'postapitbguidelines'}}, // them tieu chuan
  {per: 'can_update_tbguidelines', rule: {v5: 'putapitbguidelines'}}, // cap nhat tieu chuan
  {per: 'can_delete_tbguidelines', rule: {v5: 'deleteapitbguidelines'}}, // xoa tieu chuan
  {per: 'can_detail_tbguidelines', rule: {v5: 'getapitbguidelinesadvance'}}, // chi tiet tieu chuan
  {per: 'can_update_detail_tbguidelines', rule: {v5: 'putapitbguidelinesadvance'}}, // cap nhat chi tiet tieu chuan
  {per: 'can_create_date_tbguidelines', rule: {v5: 'postapitbguidelinedetails'}}, // them chi tiet tieu chuan theo ngay tuoi
  {per: 'can_update_date_tbguidelines', rule: {v5: 'putapitbguidelinedetails'}}, // cap nhat chi tiet tieu chuan theo ngay tuoi
  {per: 'can_delete_date_tbguidelines', rule: {v5: 'deleteapitbguidelinedetails'}}, // cap nhat chi tiet tieu chuan theo ngay tuoi
  {per: 'can_duplicate_tbguidelinedetails', rule: {v5: 'postapiduplicatetbguidelinedetails'}}, // sao chep ngay tuoi
  {per: 'can_delete_periods', rule: {v5: 'deleteapitbguidelineperiods'}}, // xoa giai doan
  {per: 'can_get_periods', rule: {v5: 'getapitbguidelineperiods'}}, // danh sách giai doan
  {per: 'can_post_periods', rule: {v5: 'postapitbguidelineperiods'}}, // them giai doan
  {per: 'can_update_periods', rule: {v5: 'putapitbguidelineperiods'}}, // cập nhật giai doan
  {per: 'can_get_detail_periods', rule: {v5: 'getapidetailtbguidelineperiods'}}, // chi tiết giai doan

  {per: 'can_list_giong', rule: {v5: 'getapitbsuppliesbytypegiong'}}, // danh sach giong
  {per: 'can_create_giong', rule: {v5: 'postapitbsuppliestypegiong'}}, // them giong
  {per: 'can_update_giong', rule: {v5: 'putapitbsuppliestypegiong'}}, // cap nhat giong
  {per: 'can_detail_giong', rule: {v5: 'getapiseeddetail'}}, // chi tiet giong
  {per: 'can_delete_giong', rule: {v5: 'deleteapitbsuppliestypegiong'}}, // xóa giong
  {per: 'can_create_warehouse_giong', rule: {v5: 'postapicreategiongInwarehouse'}}, // cap nhat kho giong
  {per: 'can_delete_warehouse_giong', rule: {v5: 'deleteapitbstockupdatesgiong'}}, // xoa lich su kho giong

  {per: 'can_list_supplies', rule: {v5: 'getapitbsupplies'}}, // danh sach vat tu
  {per: 'can_create_supplies', rule: {v5: 'postapitbsupplies'}}, // them vat tu
  {per: 'can_update_supplies', rule: {v5: 'putapitbsupplies'}}, // cap nhat vat tu
  {per: 'can_detail_supplies', rule: {v5: 'getapidetailsupplies'}}, // chi tiet vat tu
  {per: 'can_delete_supplies', rule: {v5: 'deleteapitbsupplies'}}, // xóa vat tu
  {per: 'can_create_warehouse_supplies', rule: {v5: 'postapicreatetbsuppliesInwarehouse'}}, // cap nhat kho vat tu
  {per: 'can_delete_warehouse_supplies', rule: {v5: 'deleteapitbstockupdates'}}, // xoa lich su kho vat tu

  {per: 'can_list_devices', rule: {v5: 'getapidevicesofusercustomers'}}, // danh sach thiet bi
  {per: 'can_create_devices', rule: {v5: 'postapicreatetbequipmentofcustomers'}}, // them thiet bi
  {per: 'can_update_devices', rule: {v5: 'putapiupdatetbequipmentofcustomers'}}, // cap nhat thiet bi
  {per: 'can_delete_devices', rule: {v5: 'deleteapitbequipmentofcustomers'}}, // xoa thiet bi
  {per: 'can_control_devices', rule: {v5: 'putapiequipmentofcustomersonoff'}}, // dieu khien thiet bi


  {per: 'can_list_category_devices', rule: {v5: 'getapitbequipments'}}, // danh sach danh muc thiet bi
  {per: 'can_create_category_devices', rule: {v5: 'postapicreatetbequipments'}}, // them danh muc thiet bi
  {per: 'can_update_category_devices', rule: {v5: 'postapiupdatetbequipments'}}, // cap nhat danh muc thiet bi
  {per: 'can_delete_category_devices', rule: {v5: 'deleteapitbequipments'}}, // xoa danh muc thiet bi

  {per: 'can_update_tbcustomers', rule: {v5: 'putapitbcustomers'}}, // dashboard sua don vi
  {per: 'can_list_unit_managerment', rule: {v5: 'postapitbcustomers'}},
  {per: 'can_detail_unit_managerment', rule: {v5: 'postapitbcustomers'}},
  {per: 'can_update_unit_managerment', rule: {v5: 'putapitbcustomers'}},
  {per: 'can_add_unit_managerment', rule: {v5: 'postapitbcustomers'}},
  {per: 'can_delete_unit_managerment', rule: {v5: 'deleteapitbcustomers'}},

  {per: 'can_read_activity_type', rule: {v5: 'getapitbactivitytypes'}},
  {per: 'can_detail_activity_type', rule: {v5: 'getdetailapitbactivitytypes'}},
  {per: 'can_add_activity_type', rule: {v5: 'postapitbactivitytypes'}},
  {per: 'can_update_activity_type', rule: {v5: 'postapiupdatetbactivitytypes'}},
  {per: 'can_delete_activity_type', rule: {v5: 'deleteapitbactivitytypes'}},

  {per: 'can_list_product_block', rule: {v5: 'getapitbproductblockdetails'}},
  {per: 'can_detail_product_block', rule: {v5: 'getapidetailtbproductblockdetails'}},
  {per: 'can_create_product_block_update', rule: {v5: 'postapitbproductblockdetailscreateblockupdate'}},
  {per: 'can_list_product_block_update', rule: {v5: 'getapitbproductstockupdates'}},
  {per: 'can_delete_product_block', rule: {v5: 'deleteapitbproductblockdetails'}},
  {per: 'can_list_product_unit', rule: {v5: 'getapitbproductunits'}},

];

@Injectable({providedIn: 'root'})
export class Permission {
  public listPermission = [];
  constructor(
    private userService: UserService,
    private localStorage: LocalStorageService,
  ) {}
  permissionUser(roleId: any) {
    const permissionsStore = this.localStorage.retrieve('rules');
    if (permissionsStore) {
      this.listPermission = permissionsStore;
      return this.listPermission;
    } else {
      return this.userService
        .permission(roleId)
        .toPromise()
        .then(response => {
          const result = response.body;
          this.listPermission = [];
          this.localStorage.clear('rules');
          if (result) {
            this.listPermission = this.convertRulesToPermissions(result);
            this.localStorage.store('rules', this.listPermission);
          }
          return this.listPermission;
        })
        .catch(() => {
          return [];
        });
    }
  }

  private convertRulesToPermissions(rules: IRule[]): string[] {
    const validRules: IRule[] = rules
      .filter(item => item.v4 === '*' || item.v4 === String(true) || item.v4 === String(true).toUpperCase());
    return ruleToPermission
      .filter(item => {
        const validRule = validRules.find(rule => {
          const rulePath = rule.v1.replace(/\//g, '').toLowerCase();
          if (rule.v5 === item.rule.v5) {
            return true;
          }
          if (isArray(item.rule.v5) && item.rule.v5.includes(rule.v5 as string)) {
            return true;
          }
          if (rule.v2 === '*' && (rulePath === item.rule.v5 || rulePath === `${item.rule.v5}*`)) {
            return true;
          }
          return false;
        });
        return !!validRule;
      }).map(item => item.per);
  }
}
