import {
  Routes,
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import {JhiResolvePagingParams} from 'ng-jhipster';
import {UserRouteAccessService} from '../../core';
import {SanPhamComponent} from './san-pham/san-pham.component';
import {SanPhamDetailComponent} from './san-pham-detail/san-pham-detail.component';
import {Injectable} from '@angular/core';
// import {ICrops, Crops} from './canh-tac.model';
// import {CanhTacService} from './canh-tac.service';
import {of} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import { IProduct, Product, IProductBatches, ProductBatches, SupplyStockUpdateAction } from './san-pham.model';
import {SanPhamService} from './san-pham.service';
import {ImportSanPhamWarehouseStoragePopupComponent} from './san-pham-warehouse-storage-dialog/san-pham-warehouse-storage-dialog.component';
import {SanPhamDeletePopupDialogComponent} from './san-pham-delete-dialog/san-pham-delete-dialog.component';
import {SanPhamDeleteHistoryPopupComponent} from './san-pham-delete-history-dialog/san-pham-delete-history-dialog.component';


@Injectable({providedIn: 'root'})
export class SanPhamManagementResolve implements Resolve<IProduct> {
  constructor(private service: SanPhamService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.params['id'] ? route.params['id'] : null;

    if (id) {
      return this.service
        .find(id)
        .pipe(map((sanpham: HttpResponse<IProduct>) => sanpham.body));
    }
    return of(new Product());
  }
}

@Injectable({providedIn: 'root'})
export class SanPhamHistoryDetailResolve implements Resolve<IProductBatches> {
  constructor(private service: SanPhamService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.params['id'] ? route.params['id'] : null;
    if (id) {
      const param: any = {};
      param.id = id;
      return this.service
        .getStockUpdateHistories({
          param: param
        })
        .pipe(map((sanpham: HttpResponse<IProductBatches>) => sanpham.body));
    }
    return of(new ProductBatches());
  }
}

@Injectable({providedIn: 'root'})
export class SanPhamDetailResolve implements Resolve<IProduct> {
  constructor(private service: SanPhamService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.params['sanpham'] ? route.params['sanpham'] : null;
    if (id) {
      return this.service
        .find(id)
        .pipe(map((sanpham: HttpResponse<IProduct>) => sanpham.body));
    }
    // return of(new Product());
    return of(null);
  }
}

export const sanPhamRoute: Routes = [
  {
    path: 'danh-sach-san-pham',
    component: SanPhamComponent,
    resolve: {
      pagingParams: JhiResolvePagingParams,
    },
    data: {
      authorities: ['can_list_product_block'],
      pageTitle: 'Danh sách sản phẩm',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'chi-tiet-san-pham/:sanpham',
    component: SanPhamDetailComponent,
    resolve: {
      sanPham: SanPhamDetailResolve,
      pagingParams: JhiResolvePagingParams,
    },
    data: {
      authorities: ['can_detail_product_block'],
      pageTitle: 'Chi tiết sản phẩm',
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'xoa-san-pham/:id',
    component: SanPhamDeletePopupDialogComponent,
    resolve: {
      sanPham: SanPhamManagementResolve,
    },
    data: {
      authorities: ['can_delete_product_block'],
      pageTitle: 'Xóa sản phẩm',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'nhap-kho-lo-san-pham',
    component: ImportSanPhamWarehouseStoragePopupComponent,
    resolve: {
      sanPham: SanPhamDetailResolve
    },
    data: {
      authorities: ['can_create_product_block_update'],
      pageTitle: 'Nhập kho sản phẩm',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'xuat-kho-lo-san-pham/:sanpham',
    component: ImportSanPhamWarehouseStoragePopupComponent,
    resolve: {
      sanPham: SanPhamDetailResolve
    },
    data: {
      authorities: ['can_create_product_block_update'],
      pageTitle: 'Xuất kho sản phẩm',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  },
  {
    path: 'xoa-lich-su-lo-san-pham/:id',
    component: SanPhamDeleteHistoryPopupComponent,
    resolve: {
      history: SanPhamHistoryDetailResolve,
    },
    data: {
      authorities: ['can_delete_product_block'],
      pageTitle: 'Xóa lịch sử vật tư',
    },
    canActivate: [UserRouteAccessService],
    outlet: 'popup',
  }
];
