import {Crops, ICrops} from './canh-tac.model';
import {Injectable} from '@angular/core';
import {SERVER_API_URL} from 'src/app/app.constants';
import {Observable} from 'rxjs';
import {HttpResponse, HttpClient} from '@angular/common/http';
import {ILo} from './canh-tac.model';
import {createRequestOption} from 'src/app/shared/util/request-util';
import {map} from 'rxjs/operators';
import {ILoaiHoatDong} from '../../entities/loai-hoat-dong/loai-hoat-dong.model';


type EntityType = ICrops;
interface Page {
  data: EntityType[];
}
type EntityResponseType = HttpResponse<EntityType>;
type EntityPageResponseType = HttpResponse<Page>;
type EntityArrayResponseType = HttpResponse<EntityType[]>;

@Injectable({
  providedIn: 'root',
})
export class CanhTacService {
  private resourceUrl = SERVER_API_URL + 'api/tb-crops';
  private resourceUrlSearch = SERVER_API_URL + 'api/tb-crops/list';
  private resourceTimeLineUrl = SERVER_API_URL + 'api/tb-crops-detail';
  private resourceguideLineUrl = SERVER_API_URL + 'api/tb-guidelines';
  private resourceDetailActivityTypeUrl = SERVER_API_URL + '/api/tb-activity-types-name';
  private resourceDetailCommonActivityUrl = SERVER_API_URL + '/api/get-detail-common-activity';
  private resourceUpdatePersonUrl =
    SERVER_API_URL + 'api/update-person-in-charge';

  private resourceFarmingActivityUrl = SERVER_API_URL + '/api/tb-todo-lists/list';
  constructor(private http: HttpClient) {}

  private toResourceEntity(entity: EntityType): EntityType {
    const copy = new Crops().assign(entity);
    if (copy.startDate) {
      copy.startDate = entity.startDate.format('YYYY-MM-DDTHH:mm:ss') + 'Z';
    }
    return copy;
  }

  private toEntity(res: EntityResponseType): EntityResponseType {
    return res;
  }

  private toEntities(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body.length > 0) {
    }
    return res;
  }

  query(req?: any): Observable<HttpResponse<ILo[]>> {
    const options = createRequestOption(req);
    return this.http.get<ILo[]>(this.resourceUrl, {
      params: options,
      observe: 'response',
    });
  }

  find(loId: number): Observable<HttpResponse<ILo>> {
    return this.http
      .get<ILo>(`${this.resourceUrl}/${loId}`, {observe: 'response'})
      .pipe(map((res: HttpResponse<ILo>) => res));
  }

  search(req: any, body: any): Observable<HttpResponse<ILo[]>> {
    const options = createRequestOption(req);
    return this.http.post<ILo[]>(this.resourceUrlSearch, body ,{
      params: options,
      observe: 'response',
    });
  }

  delete(loId: number): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.resourceUrl}/${loId}`, {
      observe: 'response',
    });
  }

  create(lo: any): Observable<HttpResponse<any>> {
    const copy = this.toResourceEntity(lo);
    return this.http.post(this.resourceUrl, copy, {observe: 'response'});
  }

  update(lo: any): Observable<HttpResponse<any>> {
    return this.http.put(this.resourceUrl, lo, {observe: 'response'});
  }

  timeLine(loId: any): Observable<HttpResponse<any>> {
    return this.http
      .get<any>(`${this.resourceTimeLineUrl}/${loId}`, {observe: 'response'})
      .pipe(map((res: HttpResponse<any>) => res));
  }
  guideLine(): Observable<HttpResponse<any>> {
    return this.http
      .get<any>(`${this.resourceguideLineUrl}`, {observe: 'response'})
      .pipe(map((res: HttpResponse<any>) => res));
  }
  updateCuratorUser(Ids: any): Observable<HttpResponse<any>> {
    return this.http.put(this.resourceUpdatePersonUrl, Ids, {
      observe: 'response',
    });
  }
  exportUom(loId: number, req): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get(
      `${SERVER_API_URL}api/report-nursery-monitor/${loId}`,
      {
        params: options,
        observe: 'response',
        responseType: 'blob' as 'json',
      },
    );
  }
  exportTrong(loId: number, req): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get(
      `${SERVER_API_URL}api/report-cultivate-history/${loId}`,
      {
        params: options,
        observe: 'response',
        responseType: 'blob' as 'json',
      },
    );
  }
  listEnvUpdate(loId: number): Observable<any> {
    return this.http.get(
      SERVER_API_URL + `api/list-environment-updates-display/${loId}`,
      {
        observe: 'response',
      },
    );
  }
  listEnvUpdateRealtime(loId: number): Observable<any> {
    return this.http.get(
      SERVER_API_URL + `api/list-environment-updates-display/${loId}/?disabel_interceptor_loading`,
      {
        observe: 'response',
      },
    );
  }
  listDevicesInEntity(nhaMangId: number): Observable<any> {
    return this.http.get(
        SERVER_API_URL + `api/listDevicesInEntity/${nhaMangId}`,
        {
          observe: 'response',
        },
    );
  }

  listLo(): Observable<HttpResponse<any>> {
    return this.http.get<ILo[]>(this.resourceUrl, {
        observe: 'response',
      })
      .pipe(map((res: HttpResponse<any>) => res.body));
  }
  listCropType(): Observable<any> {
    return this.http.get(
        SERVER_API_URL + `api/tb-crop-types`,
        {
          observe: 'response',
        },
    );
  }
  getDetailActivityType(activityTypeId: number): Observable<HttpResponse<ILoaiHoatDong>> {
    return this.http
      .get<ILoaiHoatDong>(`${this.resourceDetailActivityTypeUrl}/${activityTypeId}`, {observe: 'response'})
      .pipe(map((res: HttpResponse<ILoaiHoatDong>) => res));
  }

  getDetailCommonActivity(activityTypeName: string , activityId: number): Observable<HttpResponse<any>> {
    return this.http
      .get<any>(`${this.resourceDetailCommonActivityUrl}/${activityTypeName}/${activityId}`, {observe: 'response'})
      .pipe(map((res: HttpResponse<any>) => res.body));
  }

  dropdownListCrop(): Observable<HttpResponse<ILo[]>> {
    return this.http.get<ILo[]>(SERVER_API_URL + `api/tb-crops-dropdown-list`, {
      observe: 'response',
    });
  }

  getFarmingActivity(param: any, data: any): Observable<HttpResponse<any>> {
    const options = createRequestOption(param);
    return this.http.post(this.resourceFarmingActivityUrl, data, {
      params: options,
      observe: 'response'});
  }
}
