import {Component, OnInit, OnDestroy} from '@angular/core';
import {MatDialogRef, MatDialog} from '@angular/material';
import {JhiEventManager} from 'ng-jhipster';
import {Router, ActivatedRoute} from '@angular/router';
import {Crops, IGuidelines, typeCrop} from '../canh-tac.model';
import {CanhTacService} from '../canh-tac.service';
import {Area, netHouses} from '../../../shared/model/area.model';
import {AreaService} from '../../../shared/services/area.service';
import {GiongService} from '../../giong';
import {IGiong} from '../../giong/giong.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-them-lo-dialog',
  templateUrl: './canh-tac-dialog.component.html',
  styleUrls: ['./canh-tac-dialog.component.scss'],
})
export class CanhTacDialogComponent implements OnInit {
  lo: Crops;
  isSaving: boolean;
  area$: Observable<Area[]>;
  netHouses$: netHouses[];
  supPlies$: IGiong[];
  guideLine$: IGuidelines[];
  cropType: any;
  constructor(
    private dialogRef: MatDialogRef<CanhTacDialogComponent>,
    private canhTacService: CanhTacService,
    private giongService: GiongService,
    private eventManager: JhiEventManager,
    private areaService: AreaService,
    private titleService: Title,
  ) {
    this.isSaving = false;
    this.lo = new Crops();
    this.cropType = [];
  }

  ngOnInit() {
    this.area$ = this.areaService
      .listArea()
      .pipe(map((res) => (res.body ? res.body : [])));
    this.canhTacService.guideLine().subscribe((res: any) => {
      this.guideLine$ = res.body ? res.body : [];
    });
    this.canhTacService.listCropType().subscribe((res: any) => {
      this.cropType = res.body ? res.body : [];
    });
    this.giongService.queryDropdownList().subscribe((res: any) => {
      this.supPlies$ = res.body ? res.body : [];
    });
    this.getNetHouses(0);
    if (this.lo.id !== null) {
      this.titleService.setTitle('Sửa lô');
    } else {
      this.titleService.setTitle('Thêm lô');
    }
  }

  save() {
    this.isSaving = true;
    if (this.lo.id !== null) {
      this.canhTacService.update(this.lo).subscribe(
        (response) => this.onSaveSuccess(response),
        () => this.onSaveError(),
      );
    } else {
      this.canhTacService.create(this.lo).subscribe(
        (response) => this.onSaveSuccess(response),
        () => this.onSaveError(),
      );
    }
  }

  private onSaveSuccess(result) {
    this.isSaving = false;
    this.eventManager.broadcast({
      name: 'loListModification',
      content: 'Lo',
    });
    this.dialogRef.close(true);
  }

  private onSaveError() {
    this.isSaving = false;
  }

  getNetHouses(id: number) {
    if (id) {
      this.areaService.listNetHousesByArea(id).subscribe((res: any) => {
        this.netHouses$ = res.body ? res.body : [];
      });
    } else {
      this.areaService.listNetHousesAll().subscribe((res: any) => {
        this.netHouses$ = res.body ? res.body : [];
      });
    }
  }
  onAreaChange(event: any) {
    if (event) {
      this.lo.areaId = event;
      this.getNetHouses(event);
    }
  }
}

@Component({
  selector: 'app-canh-tac-popup',
  template: '',
})
export class CanhTacPopupComponent implements OnInit, OnDestroy {
  private dialogRef: MatDialogRef<CanhTacDialogComponent>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private titleService: Title,
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.dialogRef = this.dialog.open(CanhTacDialogComponent, {
        disableClose: true,
        width: '380px',
      });
      this.dialogRef.afterClosed().subscribe(
        (result) => {
          this.router.navigate([{outlets: {popup: null}}], {
            replaceUrl: true,
            queryParamsHandling: 'merge',
          });
        },
        (reason) => {
          this.router.navigate([{outlets: {popup: null}}], {
            replaceUrl: true,
            queryParamsHandling: 'merge',
          });
        },
      );
    }, 0);
  }

  ngOnDestroy() {
    this.titleService.setTitle('Danh sách lô');
    this.dialogRef = null;
  }
}
